import type { FetchError } from "ofetch";

export type LogInRequest = {
    UserName: string;
    Password: string;
};

export type LogInResponse = {
    ReturnUrl: string;
    AntiForgeryToken: string;
};

export default function useIdentity() {
    const api = useApi();
    const isAuthenticated = useStateIsAuthenticated();

    async function logIn(logInRequest: LogInRequest) {
        try {
            const loginResponse = await api<LogInResponse>("api/identity/sign-in", {
                method: "POST",
                body: logInRequest,
            });

            isAuthenticated.value = true;
            return loginResponse;
        } catch (err) {
            const fetchError = err as FetchError;
            throw new Error(fetchError.data.Error);
        }
    }

    async function logOut() {
        await api("api/identity/logout");
        isAuthenticated.value = false;
    }

    return { logIn, logOut };
}
